<template lang="pug">
main
	a.whatsappWrapper(href="https://wa.me/+917579175791?text=I'm%20inquiring%20about%20the%20properties%20from%20Website!")
		img(src='../assets/img/WhatsApp.svg')
	display-header(:img="require('@/assets/overscans/spa_2.jpg')",headline="Spa & Hammam")

	area-section(:img="require('@/assets/img/final_3d_images/low/img026.jpg')",day="Everyday",time="4:00AM - 6:00AM",title="Spa",subtitle="Just Spa it",description="Discover Nirvana and rejuvenate at our peaceful Resort")

	area-section(:img="require('@/assets/img/final_3d_images/low/img059.jpg')",day="Everyday",time="12:00AM - 12:00AM",title="Hammam",subtitle="Harmony at hammam",description="India's first Hammam bath to take away all your stress")


	location

</template>

<script>

// Imports
import DisplayHeader from '../components/DisplayHeader.vue'
import AreaSection from '../components/XAreaSection.vue'
import Location from '../components/Location.vue'

// Vue Config
export default {
	 name: "",
  components: {DisplayHeader, AreaSection, Location},
};
</script>

<style>
</style>